<template>
  <div class="main">
    <lj-nav></lj-nav>
    <div class="main-title" style="padding: 0">
      <div style="display: flex; justify-content: center">
        学生个人报告中心
        <div class="print-btn" @click="print()">
          <div class="print-btn-text-wrapper">
            <span>打印</span><span>报告</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-subtitle">一、人格特质与职业倾向</div>
    <div class="main-content">
      <div class="main-majors-wrapper">
        <div class="major" @click="jumpToPage(1)">
          <div class="major-title">职业倾向报告</div>
          <div class="major-matching-value">1</div>
        </div>
        <div class="major" @click="jumpToPage(2)">
          <div class="major-title">人格倾向报告</div>
          <div class="major-matching-value">2</div>
        </div>
        <div class="major" @click="jumpToPage(3)">
          <div class="major-title">创新素质报告</div>
          <div class="major-matching-value">3</div>
        </div>
      </div>
    </div>
    <div class="main-subtitle">二、学科优势与学科能力</div>
    <div class="main-content">
      <div class="main-majors-wrapper">
        <div class="major" @click="jumpToPage(4)">
          <div class="major-title">学科优势报告</div>
          <div class="major-matching-value">4</div>
        </div>
        <div class="major" @click="jumpToPage('subject')">
          <div class="major-title">学科能力分析报告</div>
          <div class="major-matching-value">5</div>
        </div>
        <div class="major" @click="jumpToPage('scoreandrank')">
          <div class="major-title">考试成绩和排名</div>
          <div class="major-matching-value">6</div>
        </div>
      </div>
    </div>
    <div class="main-subtitle">三、专业匹配与定位</div>
    <div class="main-content">
      <div class="main-majors-wrapper">
        <div class="major" @click="jumpToPage(5)">
          <div class="major-title">高校大类专业匹配全景报告</div>
          <div class="major-matching-value">7</div>
        </div>
        <div class="major" @click="jumpToPage(6)">
          <div class="major-title">最匹配专业详情报告(20个)</div>
          <div class="major-matching-value">8</div>
        </div>
        <div class="major" @click="jumpToPage(7)">
          <div class="major-title">小类专业排序报告</div>
          <div class="major-matching-value">9</div>
        </div>
      </div>
    </div>
    <div class="main-subtitle">四、选科推荐与规划</div>
    <div class="main-content">
      <div class="main-majors-wrapper">
        <div class="major" @click="jumpToPage('matchrequirements')">
          <div class="major-title">二十个最匹配专业选科要求</div>
          <div class="major-matching-value">10</div>
        </div>
        <div class="major" @click="jumpToPage('xuankerecommand')">
          <div class="major-title">选科推荐报告</div>
          <div class="major-matching-value">11</div>
        </div>
        <div class="major" @click="jumpToPage('xuanke')">
          <div class="major-title">选科规划报告</div>
          <div class="major-matching-value">12</div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="main-majors-wrapper">
        <div
          class="major consult-btn"
          style="background: #da7152"
          @click="jumpToPage('consult')"
        >
          报告解读与生涯服务咨询
          <i class="el-icon-question"></i>
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpToPage('home')"
        >返回首页</a
      >
      <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpToPage(1)"
        >下一页</a
      >
    </div>
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import { infoquery, agreeliability } from './../../../api/userinfo'
import { queryMajorDone } from './../../../api/major'
import LjFooter from './../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
import { querySubjectDone } from './../../../api/subject'

export default {
  data: function () {
    return {
      questionaireHollandDone: true,
      questionaireMBTIDone: true,
      questionaireDone: true,

      assresults: {
        chinese: 20,
        math: 20,
        english: 40,
        physics: 20,
        chemistry: 30,
        biology: 30,
        politics: 29,
        history: 19,
        geography: 78
      }
    }
  },
  components: {
    LjFooter,
    LjNav
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    /**
     * 产品说明，同意或者不同意
     */
    onDisagreeBtnClick: function () {
      this.$router.back()
    },
    onAgreeBtnClick: function () {
      agreeliability({
        agreemajormatching: 1
      }).then(res => {
        if (res.rescode == 200) {
          this.agreementAgreed = !0
        }
      })
    },
    /**
     * 将做题的顺序和部分进行对换
     */
    getPartDone: function (cur_q) {
      this.questionaireHollandDone = cur_q > 53 || cur_q === -1
      this.questionaireMBTIDone = cur_q >= 78 || cur_q === -1
      this.questionaireDone = cur_q > 133 || cur_q === -1
    },
    /**
     * 查询学科能力测评是否一个都没做
     */
    judgeDoneQues: function () {
      let ans = false
      Object.entries(this.assresults).forEach(entry => {
        const [key, value] = entry
        if (value !== null) {
          ans = true
        }
      })
      return ans
    },

    jumpToPage: function (e) {
      if (e == 'home') {
        this.$router.replace({
          path: '/'
        })
        return
      }
      switch (e) {
        case 1:
          this.questionaireHollandDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage1'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 2:
          this.questionaireMBTIDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage2'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 3:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage3'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 4:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage4'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 5:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage5'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 6:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage6'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 7:
          this.questionaireDone
            ? this.$router.push({
                path: 'HomeMajorStdResultPage7'
              })
            : this.$router.push({
                path: 'HomeMajorStdMatchPage'
              })
          break
        case 'consult':
          this.$router.push({
            path: 'ConsultUs'
          })
          break
        case 'xuanke':
          this.$router.push({
            path: 'monixuanke'
          })
          break
        case 'subject':
          querySubjectDone({}).then(res => {
            console.log('querySubjectDone', res)
            if (res.rescode == 200) {
              this.assresults = res.resultStatus
              if (!this.judgeDoneQues()) {
                this.$router.push({
                  path: 'HomeTestIndex'
                })
              } else {
                this.$router.push({
                  path: 'HomeTestResult'
                })
              }
            }
          })
          break
        case 'matchrequirements':
          this.$router.push({
            path: 'matchrequirements'
          })
          break
        case 'scoreandrank':
          this.$router.push({
            path: 'scoreandrank'
          })
          break
        case 'xuankerecommand':
          this.$router.push({
            path: 'xuankerecommand'
          })
          break
      }
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  },
  mounted: function () {
    if (this.isMobile !== null) {
      this.$router.replace({
        path: 'reportCenterMobile'
      })
      return
    }
    infoquery({}).then(res => {
      if (res.rescode == 200) {
        this.agreementAgreed = res.agreemajormatching
        queryMajorDone({}).then(res => {
          if (res.rescode == 200) {
            console.log('queryMajorDone', res)
            this.getPartDone(res.cur_q)
          }
        })
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/templates/home/major/std_result.min.css');
@import url('./../../../Public/resources/css/templates/home/major/std_result_index.min.css');
@import url('./../../../Public/resources/css/templates/home/major/index.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');

.main {
  background: #cfd1cc;
  padding-bottom: 0;
}
.consult-btn {
  text-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.main .main-content .main-majors-wrapper .major {
  padding: 1rem;
  margin: 1rem;
  min-width: 32rem;
}

.main .main-subtitle {
  margin-top: 1.3rem;
  font-size: 2.1rem;
}
</style>
